<template>
  <CSidebar
    fixed
    :minimize="minimize"
    :show="show"
    @update:show="(value) => $store.commit('set', ['sidebarShow', value])"
  >
    <CSidebarBrand class="d-md-down-none" to="/">
      <img
        src="/img/logo.png"
        class="c-sidebar-brand-full"
        style="max-height:48px;"
      />
      <img
        src="/img/logo-min.jpg"
        class="c-sidebar-brand-minimized"
        style="max-height:48px;"
      />
    </CSidebarBrand>

    <CRenderFunction flat :content-to-render="optionsNav()" />
    <CSidebarMinimizer
      class="d-md-down-none"
      @click.native="$store.commit('set', ['sidebarMinimize', !minimize])"
    />
  </CSidebar>
</template>

<script>
import nav from "./_nav";

export default {
  name: "TheSidebar",
  nav,
  computed: {
    show() {
      return this.$store.state.sidebarShow;
    },
    minimize() {
      return this.$store.state.sidebarMinimize;
    },
  },
  methods: {
    optionsNav() {
      let nav = this.$options.nav;
      let authUser = this.$user.getters.authUser;
      let admin = this.$const.ROLES.SuperAdmin;

      nav.forEach((item) => {
        item._children = item._children.filter(
          (c) =>
            authUser &&
            authUser.role &&
            (authUser.role == admin ||
              !c.roles ||
              !c.roles.length ||
              c.roles.includes(authUser.role))
        );
      });

      return nav;
    },
  },
};
</script>
