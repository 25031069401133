export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        _name: "CSidebarNavItem",
        name: "Dashboard",
        to: "/dashboard",
        icon: "cil-speedometer",
        roles: ["super_admin", "owner", "agent", "stock_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Hiệu quả hệ thống",
        to: "/report",
        icon: "cil-ChartPie",

        roles: ["super_admin", "owner"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Báo Cáo",
        to: "/reportV2",
        icon: "cil-chart",
        roles: ["super_admin", "owner"],
        badge: {
          color: "primary",
          text: "NEW",
        },
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Khách Hàng"],
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Ghé Thăm",
        to: "/customer/visit",
        icon: "cil-ChatBubble",
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavItem",
        name: "DS Khách hàng",
        to: "/customer/list",
        icon: "cil-Group",
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavTitle",
        _children: ["Đơn hàng"],
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Tạo đơn hàng",
        to: "/order/create",
        icon: "cil-NoteAdd",
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavItem",
        name: "DS Đơn hàng",
        to: "/order/list",
        icon: "cil-Notes",
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Đơn Trả góp",
        to: "/order/installment",
        icon: "cil-Wallet",
        roles: ["super_admin", "owner", "agent"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Chi phí cửa hàng",
        to: "/order/expense",
        icon: "cil-Money",
        roles: ["super_admin", "owner"],
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["Kho Hàng"],
        roles: ["super_admin", "owner", "stock_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Nhập Kho",
        to: "/inventory/import",
        icon: "cil-Input",
        roles: ["super_admin", "owner", "stock_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Xuất Kho",
        to: "/inventory/export",
        icon: "cil-ShareBoxed",
        roles: ["super_admin", "owner", "stock_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Kho Hàng",
        to: "/inventory/warehouse",
        icon: "cil-Storage",
        roles: ["super_admin", "owner", "stock_admin"],
      },

      {
        _name: "CSidebarNavTitle",
        _children: ["Thông tin chung"],
        roles: ["super_admin", "owner", "stock_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Sản Phẩm",
        to: "/general/product",
        icon: "cil-Gift",
        roles: ["super_admin", "stock_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Khuyến Mãi",
        to: "/general/promotion",
        icon: "cil-MobileLandscape",
        roles: ["super_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "MISC",
        to: "/general/misc",
        icon: "cil-Tags",
        roles: ["super_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Cửa Hàng",
        to: "/general/agency",
        icon: "cil-Star",
        roles: ["super_admin", "owner"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Chi Phí",
        to: "/general/expense",
        icon: "cil-Cash",
        roles: ["super_admin"],
      },
      {
        _name: "CSidebarNavItem",
        name: "Người dùng",
        to: "/general/user",
        icon: "cil-User",
        roles: ["super_admin"],
      },
    ],
  },
];
